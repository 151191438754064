<template>
  <div class="block-advantages-container xl:mt-[90px]" :class="device_platform">
    <div class="advantages-wrapper relative">
      <buttons--button-modal-caller :modal-title="advantage.text" modal-button="ПОЛУЧИТЬ" class="advantage" v-for="advantage in advantages"
        :key="advantage.text">
        <div class="advantage-img" :class="advantage.img"></div>
        <div class="advantage-text" v-html="advantage.text"></div>
      </buttons--button-modal-caller>
    </div>
  </div>
</template>

<script>
import Mixin from "../common/mixin";
import finance from "../common/finance";
import emitter from "tiny-emitter/instance";
export default {
  name: "block-advantages",
  mixins: [Mixin, finance],
  data() {
    return {
      advantages: [
        {
          text: "5 лет гарантии на <br>новый GEELY",
          img: "lock",
          callback: "Оплатим проезд до салона",
        },
        {
          text: "Специальные условия<br>для юридических лиц",
          img: "comment",
          callback: "5 лет гарантиина новый GEELY",
        },
        {
          text: "Сервис от <br>официального дилера",
          img: "gear",
          callback: "Специальные условия для юридических лиц",
        },
        {
          text: "Более 50 а/м <br>в наличии с ПТС",
          img: "car_list",
          callback: "Сервис от официального дилера",
        },
        {
          text: "0%<br>Рассрочка до 5 лет",
          img: "coins_b",
          callback: "0% Рассрочка до 5 лет",
        },
      ],
    };
  },
  methods: {
    getAgreement() {
      emitter.emit("getAgreement");
    },
  },
  mounted() {
    if (window.innerWidth < 800) {
      // window.advantages_slider = new Swiper('.block-advantages-container', {
      //     wrapperClass: 'advantages-wrapper',
      //     slideClass: 'advantage',
      //     autoplay: {
      //         delay: 15000
      //     },
      //     loop: true
      // });
    }

    window.addEventListener("resize", () => {
      if (window.innerWidth > 800) {
        // window.advantages_slider.destroy();
      }
    });
  },
};
</script>

<style scoped lang="scss">
@import "../styles/constants.scss";

.tablet {
  .advantages-wrapper {
    flex-wrap: wrap;

  }
  &.block-advantages-container {
    margin-top: 90px;
  }
}

.mobile {
  &.block-advantages-container {
    overflow: hidden;
    margin-top: -50px;
    @apply pt-[26px]
  }

  .advantages-wrapper {
    flex-wrap: nowrap;
    margin-top: 0px;
    @apply grid grid-cols-1 grid-rows-4 md:grid-cols-2 md:grid-rows-2 md:gap-[55px] px-0 md:px-[9%];
  }

  .advantage {
    width: 100%;
    flex-shrink: 0;
    @apply flex-row mx-auto gap-[25px] justify-center px-[5%] md:px-0 lg:flex-col lg:gap-0 lg:justify-center py-[33px] border-b border-black/[.15] md:border-none;
  }

  .advantage-img {
    width: 54px;
    height: 54px;
  }

  .advantage-text {
    @apply md:mt-0 min-w-[170px];
    font-size: 18px;
  }

  .header-text {
    font-size: 35px;
  }
}

.block-advantages-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // max-width: 1920px;
  background-color: #fff;
  @apply 2xl:gap-[30px];
}

.advantages-wrapper {
  width: 100%;
  max-width: 1290px;
  margin: 10px auto 10px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.advantage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @apply w-1/5 min-w-[250px];
}

.advantage-text {
  //margin-top: 25px;
  //text-align: center;
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
  @apply lg:mt-[40px] lg:text-center;
}

.advantage-img {
  width: 60px;
  height: 60px;

  @each $advantage in "location-point", "car_home", "car_lock", "wrench", "list",
    "taxi", "lock", "comment", "gear", "car_list", "coins_b"
  {
    &.#{$advantage} {
      background: url("../images/icons/#{$advantage}.png") no-repeat center;
      background-size: contain;
    }
  }
}
</style>
