<template>
  <layout-template>
    <block-banner />
    <BlockTimer
      :data="{ type: 'sale', form: 'contacts' }"
      v-on="block_handlers"
    />
    <block-advantages v-on="block_handlers" />
    <block-cars-new id="cars" v-on="block_handlers"></block-cars-new>
    <!-- <block-cars id="cars" v-on="block_handlers"></block-cars> -->
    <block-configurator
      id="configurator"
      v-on="block_handlers"
    ></block-configurator>
    <block-credit></block-credit>
    <block-tradein></block-tradein>
    <block-testdrive></block-testdrive>
    <block-choice
      id="choice"
      v-on="block_handlers"
      :b_cars_car="b_cars_car"
    ></block-choice>
    <block-map id="contacts" v-on="block_handlers"></block-map>
  </layout-template>
</template>

<script>
import BlockAnotherOffer from "./components/block-another-offer";
import BlockCredit from "./components/block-credit";
import BlockTradein from "./components/block-tradein";
import BlockTestDrive from "./components/block-testdrive";
import BlockBanner from "./components/block-banner.vue";
import BlockHeader from "./components/block-header";
import BlockMain from "./components/block-main";
import BlockClock from "./components/block-clock-megatimer";
import BlockLegal from "./components/block-legal";
import BlockCarsNew from "./components/block-cars-new";
// import BlockCars from "./components/block-cars";
import BlockInterior from "./components/block-interior";
import BlockChoice from "./components/block-choice";
import BlockConfigurator from "./components/block-configurator";
import BlockOffer from "./components/block-offer";
import BlockMap from "./components/block-map";
import BlockFooter from "./components/block-footer";
import FullGet from "./components/full-get";
import FullThanks from "./components/full-thanks";
import Mixin from "./common/mixin";
import Timer from "./components/timer-item";
import Send from "./common/send";
import $ from "jquery";
import smoothScrollTo from "./common/smoothScrollTo";
import BlockAdvantages from "./components/block-advantages";
import BlockClockMegatimer from "./components/block-clock-megatimer";
import BlockTimer from "./components/block-timer";
import emitter from "tiny-emitter/instance";


export default {
  name: "app",
  components: {
    BlockBanner,
    BlockAnotherOffer,
    BlockAdvantages,
    BlockHeader,
    BlockCarsNew,
    BlockInterior,
    BlockChoice,
    BlockConfigurator,
    BlockOffer,
    BlockFooter,
    BlockMap,
    BlockMain,
    BlockClock,
    FullGet,
    FullThanks,
    BlockLegal,
    Timer,
    BlockClockMegatimer,
    BlockTimer,
    BlockCredit,
    BlockTradein,
    BlockTestDrive,
  },
  mixins: [Mixin],
  data: function () {
    return {
      show: {
        agreement: false,
        load: false,
        get: false,
        thanks: false,
      },
      data: {},
      b_cars_car: null,
      is_webp: true,
    };
  },
  mounted() {
    $("html").on("keyup", (event) => {
      if (event.keyCode === 27) {
        this.closeThings();
      }
    });
    history.scrollRestoration = "manual";
    let hash = window.location.hash.replace("#", "");
    if (hash) {
      this.scrollTo(hash);
    }
    window.addEventListener('load', () => {
      const { search } = window.location;
      const searchParams = new URLSearchParams(search);
      if (searchParams.has('block')) {
        this.scrollTo(searchParams.get('block'))
      }
      if (searchParams.has('model')) {
        this.setChoice({name: searchParams.get('model')})
      }
    })

    this.canUseWebP();
  },
  created() {
    emitter.on("getAgreement", this.getAgreement);
    emitter.on("scrollTo", this.scrollTo);
    emitter.on("callBack", this.callThere);
    emitter.on("getCall", this.handleGetCall);
    emitter.on("bCarsClicked", this.setChoice);
  },
  methods: {
    canUseWebP() {
      let elem = document.createElement("canvas");

      if (!!(elem.getContext && elem.getContext("2d"))) {
        // was able or not to get WebP representation
        return (this.is_webp =
          elem.toDataURL("image/webp").indexOf("data:image/webp") == 0);
      }

      // very old browser like IE 8, canvas not supported
      return (this.is_webp = false);
    },
    closeThings() {
      this.show.get = false;
      this.show.thanks = false;
      this.show.agreement = false;
      this.data = {};
      this.unblockScroll();
    },
    closeAgreement() {
      this.show.agreement = false;
      if (!this.show.get) {
        this.unblockScroll();
      }
    },
    getAgreement() {
      this.show.agreement = true;

      this.blockScroll();
    },
    yaMetricaRequest(type) {
      if (
        window.yaCounter56456560 &&
        typeof window.yaCounter56456560.reachGoal === "function"
      ) {
        window.yaCounter56456560.reachGoal(type);
      } else {
        console.log("no yaCounter56456560 was found");
      }
    },
    handleGetCall(data) {
      this.yaMetricaRequest("click");
      this.show.get = true;
      this.data = data;
      this.blockScroll();
    },
    scrollTo(where, delta) {
      let target = document.getElementById(where);
      let header = document.getElementById('header')
      if (!delta) delta = 0;

      smoothScrollTo($("#" + where).offset().top - delta - header.offsetHeight);
    },
    setChoice(car) {
      this.b_cars_car = car;
    },
    callThere(event) {
      let cKeeper = this.CONSTANTS.cKeeper,
        manager_phone = this.CONSTANTS.need_manager_phone ? "74959334033" : "",
        req = Send(event, this.data, cKeeper, manager_phone);
      this.show.load = true;
      this.yaMetricaRequest("filled_form");
      if (cKeeper === "actioncall") {
        const finishCall = () => {
          this.closeThings();
          this.show.load = false;
          this.show.thanks = true;
          if (typeof window.dataLayer !== "undefined") {
            window.dataLayer.push({
              event: "callkeeper-call_order-ckaction",
              eventCategory: "callkeeper",
              eventAction: "call_order",
              eventLabel: "ckaction",
            });
          }
          console.log("done");
        };

        if (!req) {
          finishCall();
        } else {
          req.finally(finishCall);
        }
      } else {
        this.closeThings();
        this.show.load = false;
        this.show.thanks = true;
        ym(56456560,'reachGoal','sendform');
      }
    },
  },
  computed: {
    block_handlers() {
      return {
        getAgreement: this.getAgreement,
        scrollTo: this.scrollTo,
        callBack: this.callThere,
        getCall: this.handleGetCall,
        bCarsClicked: this.setChoice,
      };
    },
  },
};
</script>
