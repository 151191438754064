<template>
  <div class="relative w-full ">
    <div
      class="cursor-pointer absolute top-4 right-4 w-8 h-8 before:content-[''] before:absolute before:w-8 before:h-px before:bg-black before:rotate-45 after:content-[''] after:absolute after:w-8 after:h-px after:bg-black after:-rotate-45 z-30"
      @click="modalStore.hideModal"
    ></div>
    <div class="bg-white rounded-md z-10 relative overflow-y-auto max-h-[768px] md:h-auto xl:max-h-[768px] 2xl:overflow-auto">
      <div class="container py-4 relative">
        <h2 class="text-center uppercase text-black font-semibold text-3xl mb-4">Подробные условия акции</h2>
        <div class="grid gap-5 text-[14px] space-y-4">
         <p><b>Гарантия 5 лет</b><br>
          Гарантия распространяется на 5 лет или на 150 000 км. пробега (в зависимости от того, что наступит ранее), за исключением отдельных комплектующих автомобиля. Подробности уточняйте по тел.8 800 200 02 89 (звонок по РФ бесплатный) либо у сотрудников дилерских центров «Geely».</p>

          <p><b>Рассрочка 0,01%</b><br>
          Предложение распространяется на новые автомобили марки «Geely». Указанная процентная ставка 0,01% в год достигается при следующих условиях кредитования по кредитному продукту: первоначальный взнос от 40%, сумма кредита от 500 000 руб. срок кредитования 12 мес. Условия и тарифы могут быть изменены банком в одностороннем порядке. Банк в праве отказать в выдаче автокредита. Обеспечение по кредиту - залог приобретаемого автомобиля. Предложение действует с 01.04.2025 г. по 30.04.2025 г. Кредит предоставляется ПАО «Совкомбанк». Генеральная лицензия Банка России №963 от 5 декабря 2014 г.</p>

          <p><b>Выгода,</b> применяемая к цене автомобиля, предоставляется Дилером покупателю от розничной цены автомобиля. <br>
          <b>Выгода по программе "Трейд-ин"</b> - это единовременная и разовая скидка по программе "Трейд-ин", предоставляется дилером покупателю от розничной цены автомобиля, приобретаемого по Программе, при сдаче в трейд-ин автомобиля с пробегом. Условия настоящей программы не могут сочетаться с условиями «Программы поддержки корпоративных продаж», «Geely Лизинг». <br>
          <b>Выгода при покупке в кредит</b> предоставляется при приобретении конечным покупателем Автомобиля с использованием кредитных средств любого Банка, по условиям Программы, Дилер снижает розничную цену нового Автомобиля для конечного покупателя на сумму не менее указанной. Размер суммы зависит от модели и комплектации Автомобиля, использования программы в сочетании с программой Трейд-ин. Условия настоящей программы не могут сочетаться с условиями «Программы поддержки корпоративных продаж», «Geely Лизинг». <br>
          <b>Выгода по программе «Лояльный Трейд-ин»</b> - это единовременная и разовая скидка, предоставляется дилером покупателю от розничной цены автомобиля, приобретаемого по Программе, при сдаче в трейд-ин автомобиля марки «Geely». <br>
          <b>Выгода по программе «Специальный Трейд-ин»</b> - это единовременная и разовая скидка, предоставляется дилером покупателю от розничной цены автомобиля, приобретаемого по Программе, при сдаче в трейд-ин автомобиля популярной марки (список марок уточняйте в дилерских центрах «Geely»).</p>

          <p><sup>1</sup>Выгода в размере 290 000 рублей указана для автомобилей «Geely Emgrand», и состоит из специальной выгоды в размере 40 000 рублей и выгоды по программе «Лояльный/Специальный Трейд-ин» в размере 250 000 рублей. Программа действительна с 01.04.2025 г. по 30.04.2025 г.<br>
          <sup>2</sup>Выгода в размере 308 000 рублей указана для автомобилей «Новый Geely Preface» и состоит из специальной выгоды в размере 50 000 рублей и программы «Лояльный/Специальный Трейд-ин» в размере 258 000 рублей. Программа действительна с 01.04.2025 г. по 30.04.2025 г.<br>
          <sup>3</sup>Выгода в размере 380 000 рублей указана для автомобилей «Geely Coolray» и состоит из специальной выгоды в размере 120 000 рублей и выгоды по программе «Специальный Трейд-ин» в размере 260 000 рублей. Программа действительна с 01.04.2025 г. по 30.04.2025 г.<br>
          <sup>4</sup>Выгода в размере 440 000 рублей указана для автомобилей «Geely Cityray» и состоит из специальной выгоды в размере 50 000 рублей, выгоды при покупке комплектаций Luxury, Flagship и Flagship Sport в кредит в размере 200 000 рублей, выгоды по программе «Лояльный/Специальный Трейд-ин» в размере 190 000 рублей. Программа действительна с 01.04.2025 г. по 30.04.2025 г.<br>
          <sup>5</sup>Выгода в размере 413 000 рублей указана для автомобилей «Новый Geely Atlas» и состоит из специальной выгоды в размере 150 000 рублей и выгоды по программе «Лояльный/Специальный Трейд-ин» в размере 263 000 рублей. Программа действительна с 01.04.2025 г. по 30.04.2025 г.<br>
          <sup>6</sup>Выгода в размере 423 000 рублей указана для автомобилей «Geely Okavango» и состоит из специальной выгоды в размере 50 000 рублей и выгоды по программе «Лояльный/Специальный Трейд-ин» в размере 373 000 рублей. Программа действительна с 01.04.2025 г. по 30.04.2025 г.<br>
          <sup>7</sup>Выгода в размере 710 000 рублей указана для автомобилей «Geely Monjaro» и состоит из специальной выгоды в размере 260 000 рублей, выгоды при покупке в кредит в размере 200 000 рублей и выгоды по программе «Лояльный/Специальный Трейд-ин» в размере 250 000 рублей. Программа действительна с 01.04.2025 г. по 30.04.2025 г.</p>

          <p>Реклама. ООО «АТЦ Кунцево». Не оферта. Программа действует с 01.04.2025 г. по 30.04.2025 г. Количество автомобилей ограничено. Подробные условия и наличие автомобилей уточняйте у менеджеров ДЦ Джили Кунцево www.geely-kuntsevo.ru  либо по тел.: +7(495)933-40-33</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useModalStore } from "@/stores/modalStore";
import { useMessageStore } from "@/stores/messageStore";
import { storeToRefs } from 'pinia'
import { useDealerStore } from "@/stores/dealerStore";

const modalStore = useModalStore()
const messageStore = useMessageStore()
const dealerStore = useDealerStore();
const { modalMessage } = storeToRefs(modalStore)
const { getDealerById } = storeToRefs(dealerStore);
</script>

<style scoped>
article {
  @apply space-y-2
}
h3 {
  font-size: 16px;
  font-weight: 700;
}
h4 {
  font-size: 14px;
  font-weight: 500;
}
a[href] {
  /* text-decoration: underline; */
  color: #002E5D;
}
</style>