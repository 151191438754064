<template>
    <div class="car__img-preview">
        <div
          class="swiper-button-prev"
          :class="'slider-arrow-gallery-auto_up-' + index"
          slot="button-next"
        ></div>
        <div
          class="swiper-button-next"
          :class="'slider-arrow-gallery-auto_down-' + index"
          slot="button-prev"
        ></div>
        <swiper-container
          ref="galleryContainer"
          init="false"
          :slides-per-view="slides"
          :direction="getDirection()"
          space-between="10"
          :navigation="{
              nextEl: `.slider-arrow-gallery-auto_down-${index}`,
              prevEl: `.slider-arrow-gallery-auto_up-${index}`,
            }"
          @resize="onResize"
          class="w-full"
        >
          <swiper-slide
            lazy="true"
            :id="index + 1"
            v-for="(item, index) in car.gallery"
            :key="index"
            class="min-h-[10px]"
          >
            <a
              data-fancybox="gallery"
              :src="require(`../images/cars/cars_gallery/${car.id}/${item}`)"
            >
              <img
                ref="galleryItem"
                class="relative h-full w-full bg-white"
                loading="lazy"
                :src="require(`../images/cars/cars_gallery/${car.id}/${item}`)"

                :alt="`${car.name} slide ${item}`"
              >
            </a>
          </swiper-slide>
        </swiper-container>
    </div>
</template>

<script setup>
import { ref, onMounted, onUpdated, onUnmounted } from "vue";
import { Fancybox } from "@fancyapps/ui";
import CarsInfo from "../common/cars-info";
import '@fancyapps/ui/dist/fancybox/fancybox.css';

const props = defineProps({
  car: {
    type: Object,
  },
  index: {
    type: Number,
  },
  webP: {
    type: Boolean,
    default: true,
  }
});

const slides = ref(0);
const onResize = (e) => {
  const [swiper] = e.detail;
  swiper.changeDirection(getDirection());
};

function getDirection() {
  let windowWidth = window.innerWidth;
  let direction = windowWidth >= 1280 ? "vertical" : "horizontal";

  windowWidth >= 1280 ? slides.value = 4  
    : windowWidth >= 768 ? slides.value = 7 
    : slides.value = 2; 

  return direction;
};

const galleryItem = ref(null);
const galleryContainer = ref(null);

onMounted(() => {
  Fancybox.bind(galleryContainer.value, `[data-fancybox]`, {});

  const options = {
    root: null,
    rootMargin: "0px",
    threshold: [0.0, 0.75],
  };
  let callback = function (entries, observer) {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > 0) {
        galleryContainer.value.initialize();
      }
    });
  };
  let observer = new IntersectionObserver(callback, options);
  observer.observe(galleryContainer.value);
});

onUpdated(() => {
  Fancybox.unbind(galleryContainer.value);
  Fancybox.close();

  Fancybox.bind(galleryContainer.value, '[data-fancybox]', {});
});

onUnmounted(()=>{
  Fancybox.destroy();
});
</script>

<style scoped lang="scss">
.car__img-preview {
  display: flex;
  flex-direction: column;
  width: 125px;
  max-width: 145px;
  max-height: 450px;
  position: relative;
  padding: 40px 0;

  @media (max-width: 1280px) {
    height: 410px;
    width: 105px;
  }
  @media (max-width: 767px) {
    height: 410px;
    width: 105px;
    margin: 0 auto;
  }

  .preview-item {
    img {
      border-radius: 15px;
    }
  }
}

swiper-slide {
  margin-bottom: 15px;

  img {
    border-radius: 15px;
    object-fit: cover;
    cursor: pointer;
    height: 100%;
  }
}

.swiper-button-prev {
  left: -30px;
}
.swiper-button-next {
  right: -30px;
}

@media (min-width: 767px) {
  .swiper-button-prev {
    left: 0px;
  }
  .swiper-button-next {
    right: 0px;
  }
}

@media (min-width: 1280px) {
  .swiper-button-prev {
    top: 20px;
    left: 50%;
    transform: translateX(-50%) rotate(90deg);
  }
  .swiper-button-next {
    bottom: 0px;
    top: auto;
    left: 50%;
    transform: translateX(-50%) rotate(90deg);
  }
}

swiper-container::part(bullet-active) {
  @apply bg-primary-blue;
}

@media (max-width: 1279px) {
  swiper-container {
    height: 65px;
    width: calc(100% - 90px);
  }
}

@media (max-width: 767px) {
  swiper-container {
    width: 72vw;
    max-width: 410px;
    height: 25vw;
    max-height: 150px;
  }
}
</style>