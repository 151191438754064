<template>
  <div class="block-cars-container container" :class="device_platform">
    <div class="header-text">Модельный ряд</div>

    <div class="cars-wrapper">
      <div
        class="car"
        :id="car.id"
        :class="{ 'coming-soon': car.coming_soon }"
        v-for="(car, index) in cars"
        :key="index"
      >
        <div class="car__content">
          <div class="benefits">
            <div class="benefits__item">
              <div class="car__title">
              <span v-if="!car.no_brand">Geely </span>
              <img 
                :src="require(`../images/logos/${car.id}.png`)"
              >
            </div>
            </div>
            <div class="benefits__item" v-if="car.full_ben">
              <div class="price-wrapper">
                <p class="benefits__ben">Выгода до <b>{{car.full_ben}}&nbsp;₽</b></p>
              </div>
            </div>
            <div class="benefits__item" v-if="car.price">
              <div class="price-wrapper">
                <p class="benefits__price">Автомобили в наличии с&nbsp;ПТС<br>РАССРОЧКА 0% до 5 лет!</p>
              </div>
            </div>

            <div
              class="benefits__item"
              v-if="car.credit_ben"
            >
              <img
                class="benefits__icon"
                src="../images/icons/trade_in.png"
                alt=""
              />
              <p class="benefits__text">Выгода при кредите 
                <span class="benefits__color">{{car.credit_ben}}</span>&nbsp;₽
              </p>
            </div>
            <div
              class="benefits__item"
              v-if="car.kasko_ben && car.id == 'atlas' && false"
            >
              <img
                class="benefits__icon"
                src="../images/icons/trade_in.png"
                alt=""
              />
              <p class="benefits__text">Выгода при КАСКО страховании</p>
            </div>
            <div class="benefits__item" v-if="car.spec_ben">
              <img
                class="benefits__icon"
                src="../images/icons/trade_in.png"
                alt=""
              />
              <p class="benefits__text">
                Специальная выгода
                <span class="benefits__color">{{ car.spec_ben }}</span>&nbsp;₽
              </p>
            </div>
            <div
              class="benefits__item"
              v-if="car.trade_in_low || car.trade_in_high"
            >
              <img
                class="benefits__icon"
                src="../images/icons/trade_in.png"
                alt=""
              />
              <p class="benefits__text">
                Скидка по трейд-ин до
                <span class="benefits__color">{{
                  is_lb ? car.trade_in_high : car.trade_in_ben_low
                }}</span>&nbsp;₽
              </p>
            </div>
            <div
              class="benefits__item"
              v-if="car.salon_benefit"
            >
              <img
                class="benefits__icon"
                src="../images/icons/trade_in.png"
                alt=""
              />
              <p class="benefits__text">
                Выгода от салона до
                <span class="benefits__color">
                  {{ car.salon_benefit }}
                </span>&nbsp;₽
              </p>
            </div>
            <div class="benefits__item" v-if="car.individual_ben">
              <img
                class="benefits__icon"
                src="../images/icons/trade_in.png"
                alt=""
              />
              <p class="benefits__text">{{ car.individual_ben }}</p>
            </div>
          </div>
        </div>
        <div class="car__img-block">
          <img
            class="car__img"
            :class="{ car__img_small: car.img_small }"
            :src="require(`../images/cars/car-${car.id}.jpg`)"
            alt=""
          />
        </div>
        <block-cars-gallery
          :car="car"
          :index="index"
          v-if="car.gallery && car.gallery?.length > 0"
        ></block-cars-gallery>
        <div class="buttons-container">
          <buttons--button-modal-caller modal-title="РАССЧИТАТЬ КРЕДИТ" modal-button="РАССЧИТАТЬ" class="btn-blue btn__arrow_1">
            РАССЧИТАТЬ КРЕДИТ
          </buttons--button-modal-caller>
          <buttons--button-modal-caller modal-title="СТОИМОСТЬ ПО АКЦИИ" modal-button="РАССЧИТАТЬ" class="btn-blue btn__arrow_2">
            СТОИМОСТЬ ПО АКЦИИ
          </buttons--button-modal-caller>
          <a
            class="uppercase text-[#002E5D] bg-[#DDDCE2] font-medium h-[50px] justify-center items-center w-full border border-[#DDDCE2] transition-colors hover-avail:hover:bg-transparent hover-avail:hover:border-[#002E5D]"
            :class="[count(car.stock_selector) > 0 ? 'flex' : 'hidden']"
            :href="`?model=${car.stock_selector}&block=in_stock`"
            v-if="stockStore.all.value"
          >
            ВЫБРАТЬ ИЗ НАЛИЧИЯ
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUpdated, onUnmounted } from "vue";
// import { Fancybox } from "@fancyapps/ui";
import Mixin from "../common/mixin";
import CarsInfo from "../common/cars-info";
import finance from "../common/finance";
import emitter from "tiny-emitter/instance";
import ImageItem from './common/Image-Item.vue';
import { useStockStore } from '@/stores/stockStore';

import $ from "jquery";
export default {
  name: "block-cars",
  components: {
    // SelectorBar,
    ImageItem,
  },
  mixins: [Mixin, finance],
  setup() {
    const stockStore = useStockStore();
    return {
      stockStore
    }
  },
  CarsInfo,
  data() {
    return {
      cars: null,
      is_lb: false,
    };
  },
  emits: ["setActiveCar", "scrollTo", "bCarsClicked"],
  methods: {
    scrollTo(where, id, car) {
      let newhash = "#" + where;
      history.replaceState(null, null, newhash);
      var delta = 0;
      if (this.device_platform === "desktop") {
        if ($(window).width() < 1607) delta = 30;
        else delta = -30;
      } else delta = -30;
      emitter.emit("setActiveCar", id);
      emitter.emit("scrollTo", where, delta);
      if (car.stock_selector)
        emitter.emit("bCarsClicked", { name: car.stock_selector });
    },
    getCall(data) {
      emitter.emit("getCall", data);
    },
    getBenefits() {
      const today = new Date();
      if (today.getHours() >= "18" || today.getHours() < "10") {
        this.is_lb = !this.is_lb;
      }
    },
    count(name) {
      if ( this.stockStore.all.value ) {
        const target = this.stockStore.all.value.find(x => x.name.includes(name))
        return target?.count;
      } else {
        return 0;
      }
    }
  },
  created() {
    this.getBenefits();
    this.cars = CarsInfo.CARS.filter(car => car.status === 1).sort((a, b) => a.order > b.order);
  },
};
</script>

<style scoped lang="scss">
@import "../styles/constants.scss";

.tablet {
  .car {
    flex-direction: column;
  }
}

.block-cars-container {
  width: 100%;
  padding-top: 160px;
  position: relative;

  @media (max-width: 1440px) {
    padding-top: 90px;
  }
}

.header-text {
  text-transform: uppercase;
  margin-top: 20px;

  @media (max-width: 600px) {
    margin-bottom: 40px;
  }
}

.cars-wrapper {
  //margin: 0px auto;
  width: 100%;
  // max-width: 1640px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.car {
  gap: 15px;
  cursor: default;
  width: 100%;
  position: relative;
  display: grid;
  justify-content: space-between;
  z-index: 1;
  grid-auto-flow: row;

  @media (max-width: 1279px) {
    grid-template-columns: repeat(2, 1fr);

    .car__img-preview {
      grid-column: span 2;
      width: auto;
      max-width: unset;
      height: unset;
      padding: 10px 0;
    }

    .buttons-container {
      grid-column: span 2;
    }

    .car__img-block {
      align-self: stretch;
    }
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &:not(:last-child) {
    margin-bottom: 100px;

    @media (max-width: 1440px) {
      margin-bottom: 80px;
    }
    @media (max-width: 600px) {
      margin-bottom: 50px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    height: 100%;
    background-color: #f0f0f0;
    padding: 28px;
    border-radius: 30px;

    @media (max-width: 1600px) {
        padding: 28px;
    }
    @media (max-width: 767px) {
      max-width: unset;
      min-width: auto;
      width: 100%;
      //padding: 0 20px;
    }
  }

  &__img-block {
    position: relative;
    display: flex;
    max-height: 450px;
    height: 100%;

    @media (max-width: 1500px) {
      align-self: flex-start;
    }
    @media (max-width: 1440px) {
      width: 100%;
    }
  }

  &__bg {
    @media (max-width: 1500px) {
      width: 100%;
    }
  }

  &__img {
    width: 700px;
    transition: all 0.5s;
    border-radius: 30px;

    @media (max-width: 1500px) {
      width: 80%;
    }
    @media (max-width: 600px) {
      bottom: -80px;
    }

    &_small {
      width: 100%;
      object-fit: cover;

      @media (max-width: 1500px) {
        width: 100%;
      }
      @media (max-width: 767px) {
        height: 55vw;
      }
    }

    &:hover {
      //transform: translateX(30px);
    }
  }

  &__title {
    left: 280px;
    color: #000;
    font-size: 48px;
    font-weight: 400;
    width: fit-content;
    text-align: center;
    filter: invert(1);

    @media (max-width: 1400px) {
      left: unset;
      right: 25px;
    }
    @media (max-width: 600px) {
      top: 20px;
      font-size: 25px;
    }
  }

  .car__title img {
    width: 400px;
    margin: 0 auto;

    @media (min-width: 1441px) and (max-width: 1535px) {
      width: 300px;
    }
    @media (min-width: 1201px) and (max-width: 1279px) {
      width: 300px;
    }
    @media (max-width: 767px) {
      width: 200px;
    }
    @media (max-width: 375px) {
      width: 45vw;
    }
  }

  &__model {
    text-transform: uppercase;
    font-size: 72px;
    font-weight: 500;

    @media (max-width: 1400px) {
      font-size: 52px;
    }
    @media (max-width: 600px) {
      font-size: 30px;
    }
  }

  .car__img-block {
    //order: 2;

    @media (max-width: 820px) {
      order: 0;
    }
  }
  .car__img {
    //left: auto;
    //right: 50px;

    &:hover {
      //transform: translateX(-30px);
    }
  }
  .car__title {
    left: 70px;

    @media (max-width: 1400px) {
      right: unset;
      left: 25px;
    }
  }
}

.benefits {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 15px;

      @media (max-width: 600px) {
        margin-bottom: 10px;
      }
    }
  }

  &__icon {
    width: 35px;
    margin-right: 25px;

    @media (max-width: 1023px) {
      margin-right: 5px;
    }

    @media (max-width: 600px) {
      width: 30px;
      margin-right: 16px;
    }
  }

  &__text {
    font-size: 20px;

    @media (max-width: 1600px) {
      font-size: 15px;
    }
    @media (max-width: 600px) {
      font-size: 16px;
    }
  }
  &__color {
    font-size: 24px;
    color: #002e5d;
    white-space: nowrap;

    @media (max-width: 1023px) {
      font-size: 18px;
    }
    @media (max-width: 767px) {
      font-size: 22px;
    }
  }

  &__price {
    font-size: 28px;
    font-weight: 500;
    font-family: "NeoSansCyr";

    @media (max-width: 1600px) {
      font-size: 22px;
    }
    @media (max-width: 1023px) {
      font-size: 16px;
    }
    @media (max-width: 600px) {
      font-size: 20px;
    }

    &_new {
      font-size: 22px;
      padding: 4px 8px 6px 8px;
      background: #cbd6dc;
      position: relative;

      @media (max-width: 600px) {
        font-size: 16px;
      }
    }
  }

  &__ben {
    font-size: 38px;
    font-family: "NeoSansCyr";

    @media (max-width: 1600px) {
      font-size: 32px;
    }
    @media (max-width: 1023px) {
      font-size: 22px;
    }
    @media (max-width: 600px) {
      font-size: 20px;
    }
  }

  &__price-color {
    color: #002e5d;
    text-decoration: line-through;
  }

  &__price-new {
    color: #002e5d;
  }

  .price-wrapper {
    display: flex;
    align-items: center;

    @media (max-width: 400px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.ruble {
  font-family: "ruble";
  font-size: 32px;

  @media (max-width: 600px) {
    font-size: 20px;
  }

  &_small {
    font-size: 20px;
    font-weight: 400;
    font-size: 24px;

    @media (max-width: 600px) {
      font-size: 18px;
    }
  }
}

.buttons-container {
  width: 100%;
  grid-column: span 3;
  display: flex;
  gap: 20px;

  @media (max-width: 1280px) {
    flex-direction: column;
    gap: unset;
  }
}

.btn-blue {
  border: 1px solid $blue;
  width: 100%;
  border-radius: 0;
  overflow: hidden;
  height: 50px;
  transition: all 0.5s;

  @media (max-width: 1500px) {
    //max-width: 400px;
  }
  @media (max-width: 600px) {
    height: 45px;
    max-width: 100%;
  }
  @media (max-width: 400px) {
    height: 40px;
  }

  &:not(:last-child) {
    margin-bottom: 20px;

    @media (max-width: 600px) {
      margin-bottom: 10px;
    }
  }
}

.btn__arrow {
  &_1 {
    position: relative;
    background: transparent;
    width: 100%;
    border: 0;

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
/*      background: url(../images/icons/btn_bg.png) no-repeat center;*/
/*      background-size: 100%;*/
      background-color: #002e5d;
      z-index: -1;
    }

    &:hover {
      /*background: $blue;
      color: #fff;*/
      background: #cbd6dc;
      color: #000;
    }
  }

  &_2 {
    position: relative;
    background: $blue;
    width: 100%;
    border: 0;

    &:after {
      content: "";
      position: absolute;
      width: 93px;
      height: 100%;
      top: 0;
      right: 0;
      background: url(../images/icons/arrow-btn.png) no-repeat center;
      background-size: 100%;

      @media (max-width: 400px) {
        right: -45px;
      }
    }

    &:hover {
      background: #cbd6dc;
      color: #000;
    }
  }
}

.btn-blue:hover {
  border: 0;
  width: 100%;
}

.btn-white {
  width: 100%;
}

.btn-white:hover {
  width: 100%;
}

sup {
  font-size: 16px;
}
</style>
